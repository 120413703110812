import React from "react";

import * as IMAGES from "../../Assets/NFImages";
import BaseUrl from "../BaseUrl";
import ApiSuffix from "../ApiSuffix";
// import CountryCode from "../CountryCode";

import { Button, CircularProgress } from "@material-ui/core";

// import MenuItem from "@material-ui/core/MenuItem";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";

// import ReactGA from "react-ga";

import "./Header.css";

class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      userEmail: "",
      userNameError: false,
      userEmailError: false,
      userMobNoError: false,
      otpLogin: true,
      sendingOtp: false,
      otpLevel: 0,
      otpMobileNo: "",
      verifyingOtp: false,
      otpErrorMessage: "",
      registerMode: false,
      registeringUser: false,
    };
    this.baseUrl = BaseUrl;
    this.apiSuffix = ApiSuffix;
  }

  handleUserNameChange = (ev) => {
    this.setState({ userName: ev.target.value });
  };

  handleUserEmailChange = (ev) => {
    this.setState({ userEmail: ev.target.value.toLowerCase().trim() });
  };

  changeMobileNo = (e) => {
    this.setState({ otpMobileNo: e.target.value, mobNoError: false });
  };

  generateOTP = () => {
    if (this.state.otpMobileNo && this.state.otpMobileNo.length === 10) {
      this.setState({ sendingOtp: true, otpNo: "" });
      let url =
        this.baseUrl.dev +
        this.apiSuffix.generateOTP +
        this.state.otpMobileNo +
        "/";
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          return response.json();
        })
        .then((data) => {
          if (data.status === 1)
            this.setState({ sendingOtp: false, otpLevel: 1 });
          else {
            this.setState({
              sendingOtp: false,
              otpErrorMessage: data.error_msg,
            });
          }
        })
        .catch((error) => {
          this.setState({ sendingOtp: false, otpLevel: 1 });
          console.error("Error:", error);
        });
    } else {
      this.setState({ mobNoError: true });
    }
  };

  verifyOtp = () => {
    this.setState({ verifyingOtp: true });
    let url =
      this.baseUrl.dev +
      this.apiSuffix.verifyOTP +
      this.state.otpMobileNo +
      "/" +
      this.state.otpNo +
      "/";
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({ verifyingOtp: false });
        if (data.status === 1) {
          if (data.type === "signup") {
            this.setState({ registerMode: true });
          } else {
            // ReactGA.initialize("UA-162083606-1");
            // ReactGA.event({
            //   category: "User",
            //   action: "User-Login",
            // });
            localStorage.setItem("userInfo", JSON.stringify(data));
            localStorage.setItem("token", "Token " + data.token);
            this.props.processLoginResponse(data);
          }
        } else {
          this.setState({ otpErrorMessage: data.error_msg });
        }
      })
      .catch((error) => {
        this.setState({ verifyingOtp: false, otpErrorMessage: "" });
        console.error("Error:", error);
      });
  };

  handleOtpChange = (e) => {
    this.setState({ otpNo: e.target.value, otpErrorMessage: "" });
  };

  resetOTP = () => {
    this.setState({ otpLevel: 0, otpMobileNo: "", otpErrorMessage: "" });
  };

  validateRegister = () => {
    this.setState({
      userNameError: false,
    });
    if (
      // new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
      //   this.state.userEmail.trim()
      // ) &&
      this.state.userName.length > 0
    ) {
      this.registerDetails();
    } else {
      if (this.state.userName.length === 0) {
        this.setState({ userNameError: true });
      }
    }
  };

  registerDetails = () => {
    let registerData = {
      mobile: this.state.otpMobileNo,
      name: this.state.userName.trim(),
    };
    let url = this.baseUrl.dev + this.apiSuffix.registerNewUser;
    this.registerUserFlow(url, registerData);
  };

  registerUserFlow = (url, registerData) => {
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(registerData),
    })
      .then((response) => {
        if (!response.ok) {
          return response.json().then((err) => {
            throw Error(err.message);
          });
        }
        return response.json();
      })
      .then((data) => {
        if (data.status === 1) {
          this.setState({ registeringUser: false });
          localStorage.setItem("userInfo", JSON.stringify(data));
          localStorage.setItem("token", "Token " + data.token);
          data.username = registerData.username;
          this.props.processLoginResponse(data);
        } else {
          this.setState({
            registeringUser: false,
            otpErrorMessage: data.error_msg,
          });
        }
      })
      .catch((error) => {
        this.setState({
          registeringUser: false,
        });
        console.error("Error:", error.message);
      });
  };

  render = () => {
    return (
      <React.Fragment>
        <div className="lrd-dialog-wrapper">
          <div className="lrd-left-panel">
            <img
              src={IMAGES.LOGIN_IMAGE}
              alt="Muffins"
              style={{ height: "540px", display: "block" }}
            />
          </div>
          {this.state.registerMode ? (
            <div className="lrd-right-panel">
              <div className="lrd-heading">
                Welcome to the Land of Mobiles and Electronics
              </div>
              <div className="lrd-subheading">Fill details to continue.</div>
              <div className="lrd-login-form">
                <TextField
                  id="userName"
                  type="text"
                  label="Name"
                  fullWidth
                  style={{ margin: "10px 0px" }}
                  margin="dense"
                  variant="outlined"
                  value={this.state.userName}
                  placeholder="Please enter your Name"
                  onChange={this.handleUserNameChange}
                  error={this.state.userNameError}
                  helperText={
                    this.state.userNameError
                      ? "Please enter a valid name"
                      : null
                  }
                />
                {/* <TextField
                  id="user-email"
                  type="text"
                  label="Email"
                  fullWidth
                  className="text-field-margin"
                  margin="dense"
                  variant="outlined"
                  value={this.state.userEmail}
                  placeholder="Please enter your email."
                  onChange={this.handleUserEmailChange}
                  error={this.state.userEmailError}
                  helperText={
                    this.state.userEmailError
                      ? "Please enter a valid Email"
                      : null
                  }
                /> */}
                {this.state.registeringUser ? (
                  <CircularProgress />
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => this.validateRegister()}
                  >
                    <div className="font-base-bold">Continue</div>
                  </Button>
                )}
              </div>
            </div>
          ) : (
            <div className="lrd-right-panel">
              <div className="lrd-heading">
                Welcome to the land of Mobiles and Electronics.
              </div>
              <div className="lrd-subheading">Verify Number to continue.</div>
              <div className="lrd-login-form">
                {/* <FormControl>
                  <Select
                    value={this.state.dialCode}
                    style={{ width: "245px" }}
                    margin="dense"
                    variant="outlined"
                    onChange={this.handleDialCodeChange}
                    disabled={this.state.otpLevel === 1}
                  >
                    {this.countryCode.map((cty, index) => (
                      <MenuItem key={index} value={cty}>
                        <div style={{ width: "130px" }}>{cty}</div>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
                {/* <Autocomplete
                  id="country-select-demo"
                  // style={{ width: 300 }}
                  value={this.state.dialString}
                  onChange={this.handleDialCodeChange}
                  options={this.countryCode}
                  autoHighlight
                  getOptionLabel={(option) => option}
                  disableClearable
                  renderOption={(option) => (
                    <React.Fragment>{option}</React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose a country"
                      variant="outlined"
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                /> */}
                <TextField
                  id="user-mobile"
                  type="number"
                  label="Whatsapp Number"
                  fullWidth
                  className="text-field-margin"
                  variant="outlined"
                  disabled={this.state.otpLevel === 1}
                  value={this.state.otpMobileNo}
                  placeholder="Enter your Whatsapp number"
                  onChange={this.changeMobileNo}
                  error={this.state.mobNoError}
                  helperText={
                    this.state.mobNoError
                      ? "Please enter a valid Mobile Number"
                      : null
                  }
                />
                {this.state.otpLevel === 1 ? (
                  <TextField
                    id="otp"
                    type="number"
                    label="OTP"
                    fullWidth
                    className="text-field-margin"
                    variant="outlined"
                    value={this.state.otpNo}
                    placeholder="Enter your OTP"
                    onChange={this.handleOtpChange}
                  />
                ) : null}
                {this.state.otpErrorMessage &&
                this.state.otpErrorMessage.length > 0 ? (
                  <div className="otp-error">{this.state.otpErrorMessage}</div>
                ) : null}
                {this.state.otpLevel === 0 ? (
                  this.state.sendingOtp ? (
                    <div>
                      <CircularProgress />
                    </div>
                  ) : (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={(e) => this.generateOTP()}
                    >
                      <div className="font-base-bold">Send OTP</div>
                    </Button>
                  )
                ) : this.state.verifyingOtp ? (
                  <div>
                    <CircularProgress />
                  </div>
                ) : (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => this.verifyOtp()}
                  >
                    <div className="font-base-bold">Verify OTP</div>
                  </Button>
                )}
                {this.state.otpLevel === 1 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={this.resetOTP}
                      style={{ margin: " 0px 10px " }}
                    >
                      <div className="font-base-bold">Reset</div>
                    </Button>
                    {this.state.sendingOtp ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={this.generateOTP}
                        style={{ margin: "5px 0px" }}
                      >
                        <div className="font-base-bold">Resend OTP</div>
                      </Button>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  };
}

export default Authentication;
