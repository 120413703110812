import {
  ADD_ITEM,
  REMOVE_ITEM,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  UPDATE_METADATA,
  UPDATE_SHIPPING,
  UPDATE_PINCODE_LIST,
  UPDATE_CITY_LIST,
  RESET_CART,
  UPDATE_ADDONS,
  UPDATE_SAVED_ADDRESS,
  UPDATE_SELECTED_ADDRESS_INDEX,
  UPDATE_RECIEVER_DATA,
  UPDATE_REFFER,
  UPDATE_PROMOTION,
  UPDATE_LOYALTY,
  UPDATE_TOTAL_LOYALTY,
  UPDATE_DELIVERY_TYPE,
  UPDATE_DELIVERY_DATE,
  UPDATE_TRANSACTION_ID,
  CHANGE_AUTH_TOKEN,
  CHANGE_AUTH_STATUS,
  CHANGE_USER_PROFILE,
  UPDATE_ADV_DETAILS,
  UPDATE_PHONE_PE,
  UPDATE_TAB_VALUE,
  UPDATE_CATEGORY_DATA,
  UPDATE_PRODUCT_DATA,
  UPDATE_VALIDATED_CART_RESPONSE,
  UPDATE_DELIVERY_RESPONSE,
  UPDATE_CART_VARIANTS,
  LOGIN,
  LOGOUT,
  MATCH_ADDONS,
} from "./actionTypes";

export const addToCart = (product) => ({
  type: ADD_ITEM,
  payload: { product },
});

export const removeFromCart = (index) => ({
  type: REMOVE_ITEM,
  payload: { index },
});

export const increaseQuantity = (index) => ({
  type: INCREASE_QUANTITY,
  payload: { index },
});

export const decreaseQuantity = (index) => ({
  type: DECREASE_QUANTITY,
  payload: { index },
});

export const updateCartMetaData = () => ({
  type: UPDATE_METADATA,
});

export const updateShipping = (shipping) => ({
  type: UPDATE_SHIPPING,
  payload: { shipping },
});

export const updatePincodeList = (pincodes) => ({
  type: UPDATE_PINCODE_LIST,
  payload: { pincodes },
});

export const updateCityList = (cities) => ({
  type: UPDATE_CITY_LIST,
  payload: { cities },
});

export const resetCart = () => ({
  type: RESET_CART,
});

export const updateAddons = (addons) => ({
  type: UPDATE_ADDONS,
  payload: { addons },
});

export const matchAddons = () => ({
  type: MATCH_ADDONS,
});

export const updateSelectedAddressIndex = (index) => ({
  type: UPDATE_SELECTED_ADDRESS_INDEX,
  payload: { index },
});

export const updateSavedAddress = (address) => ({
  type: UPDATE_SAVED_ADDRESS,
  payload: { address },
});

export const updateRecieverData = (mobNo, name) => ({
  type: UPDATE_RECIEVER_DATA,
  payload: { mobNo, name },
});

export const updateReffer = (reffer, amount) => ({
  type: UPDATE_REFFER,
  payload: { reffer, amount },
});

export const updatePromotion = (promoCode, promoAmount) => ({
  type: UPDATE_PROMOTION,
  payload: { promoCode, promoAmount },
});

export const updateLoyalty = (points, promoAmount) => ({
  type: UPDATE_LOYALTY,
  payload: { points, promoAmount },
});

export const updateTotalLoyalty = (points) => ({
  type: UPDATE_TOTAL_LOYALTY,
  payload: { points },
});

export const updateDeliveryType = (delType) => ({
  type: UPDATE_DELIVERY_TYPE,
  payload: { delType },
});

export const updateDeliveryDate = (delDate) => ({
  type: UPDATE_DELIVERY_DATE,
  payload: { delDate },
});

export const updateTransactionId = (transId) => ({
  type: UPDATE_TRANSACTION_ID,
  payload: { transId },
});

export const updateAuthToken = (token) => ({
  type: CHANGE_AUTH_TOKEN,
  payload: { token },
});

export const updateAuthStatus = (status) => ({
  type: CHANGE_AUTH_STATUS,
  payload: { status },
});

export const updateUserProfile = (profile) => ({
  type: CHANGE_USER_PROFILE,
  payload: { profile },
});

export const updateAdvDetails = (details) => ({
  type: UPDATE_ADV_DETAILS,
  payload: { details },
});

export const updateTabValue = (tabValue) => ({
  type: UPDATE_TAB_VALUE,
  payload: { tabValue },
});

export const updateCategoryData = (catData) => ({
  type: UPDATE_CATEGORY_DATA,
  payload: { catData },
});

export const updateProductData = (prodData) => ({
  type: UPDATE_PRODUCT_DATA,
  payload: { prodData },
});

export const updateValiadtedCartResponse = (cartResponse) => ({
  type: UPDATE_VALIDATED_CART_RESPONSE,
  payload: { cartResponse },
});

export const updateDeliveryResponse = (delResponse) => ({
  type: UPDATE_DELIVERY_RESPONSE,
  payload: { delResponse },
});

export const updateCartVariants = (cartList) => ({
  type: UPDATE_CART_VARIANTS,
  payload: { cartList },
});

export const updatePhonePe = (status) => ({
  type: UPDATE_PHONE_PE,
  payload: { status },
});

export const login = (token, profile) => ({
  type: LOGIN,
  payload: { token, profile },
});

export const logout = () => ({
  type: LOGOUT,
});
