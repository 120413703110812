import React, { Suspense, lazy } from "react";

import { Provider } from "react-redux";
import store from "./Redux/store";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

import Header from "./Util/Header/Header";
import Loader from "./Util/Loader/Loader";

import "./App.css";

const Home = lazy(() => import(/* webpackChunkName: "Home" */ "./Home/Home"));

const AboutUs = lazy(() =>
  import(/* webpackChunkName: "About" */ "./AboutUs/AboutUs")
);

const Catalogue = lazy(() =>
  import(/* webpackChunkName: "Catalogue" */ "./Catalogue/Catalogue")
);

const CategoryMenu = lazy(() =>
  import(/* webpackChunkName: "CategoryMenu" */ "./CategoryMenu/CategoryMenu")
);

const ProductMenu = lazy(() =>
  import(/* webpackChunkName: "ProductMenu" */ "./ProductMenu/ProductMenu")
);

const Mobiles = lazy(() =>
  import(/* webpackChunkName: "Mobiles" */ "./Product/Mobiles")
);

const Laptops = lazy(() =>
  import(/* webpackChunkName: "Laptops" */ "./Product/Laptops")
);

// const Product = lazy(() =>
//   import(/* webpackChunkName: "Product" */ "./Product/Product")
// );

const Cart = lazy(() => import(/* webpackChunkName: "Cart" */ "./Cart/Cart"));

const Checkout = lazy(() =>
  import(/* webpackChunkName: "Checkout" */ "./Checkout/Checkout")
);

const PaymentInitiate = lazy(() =>
  import(/* webpackChunkName: "Payment" */ "./Checkout/PaymentInitiate")
);

const CODPayment = lazy(() =>
  import(/* webpackChunkName: "COD-Payment" */ "./Checkout/CODPayment")
);

const VerifyPayment = lazy(() =>
  import(/* webpackChunkName: "VerifyPayment" */ "./Checkout/VerifyPayment")
);

const PaymentSuccess = lazy(() =>
  import(
    /* webpackChunkName: "PaymentSuccess" */ "./PaymentUrls/PaymentSuccess"
  )
);

const PaymentFailed = lazy(() =>
  import(/* webpackChunkName: "PaymentFailed" */ "./PaymentUrls/PaymentFailed")
);

const PaymentCancelled = lazy(() =>
  import(
    /* webpackChunkName: "PaymentCancelled" */ "./PaymentUrls/PaymentCancelled"
  )
);

const Profile = lazy(() =>
  import(/* webpackChunkName: "Profile" */ "./Profile/Profile")
);

const OrderDetail = lazy(() =>
  import(/* webpackChunkName: "OrderDetail" */ "./OrderDetail/OrderDetail")
);

const Pages = lazy(() =>
  import(/* webpackChunkName: "Pages" */ "./Pages/Pages")
);

const Blog = lazy(() => import(/* webpackChunkName: "Blog" */ "./Blog/Blog"));

const BlogContent = lazy(() =>
  import(/* webpackChunkName: "BlogContent" */ "./Blog/BlogContent")
);

const ContactUs = lazy(() =>
  import(/* webpackChunkName: "Contact" */ "./ContactUs/ContactUs")
);

const Referral = lazy(() =>
  import(/* webpackChunkName: "Referral" */ "./Referral/Referral")
);

// const Bill = lazy(() =>
//   import(/* webpackChunkName: "QRInfo" */ "./Profile/Bill")
// );

const theme = createTheme({
  palette: {
    primary: {
      main: "#004DCB",
    },
    secondary: {
      main: "#fedb3b",
    },
  },
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.customHistory = createBrowserHistory();
  }

  render = () => {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={this.customHistory}>
            <Suspense
              fallback={
                <div className="loader-container">
                  <Loader />
                </div>
              }
            >
              <Header history={this.customHistory} />
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/shop" component={Catalogue} />
                <Route path="/category/:cat" component={CategoryMenu} />
                <Route path="/products/:cat" component={ProductMenu} />
                <Route path="/shop/mobiles/:barcode" component={Mobiles} />
                <Route path="/shop/laptops/:barcode" component={Laptops} />
                {/* <Route path="/store-menu/:storeId" component={Category} /> */}
                {/* <Route path="/buy/:prodUrl" component={Product} /> */}
                <Route path="/checkout" component={Checkout} />
                <Route path="/cart" component={Cart} />
                <Route path="/payment" component={PaymentInitiate} />
                <Route path="/cod-payment" component={CODPayment} />
                <Route
                  path="/check-payment-status/:payId"
                  component={VerifyPayment}
                />
                <Route
                  path="/payment-success/:orderId"
                  component={PaymentSuccess}
                />
                <Route path="/payment-failed" component={PaymentFailed} />
                <Route path="/payment-cancelled" component={PaymentCancelled} />
                <Route path="/profile" component={Profile} />
                <Route path="/order/:orderId" component={OrderDetail} />
                <Route path="/pages/:pageType" component={Pages} />
                <Route path="/about-us" component={AboutUs} />
                <Route path="/blog" component={Blog} />
                <Route path="/blog-content/:id" component={BlogContent} />
                <Route path="/contact-us" component={ContactUs} />
                <Route path="/referral/:token" component={Referral} />
                {/* <Route path="/bl/:id" component={Bill} /> */}
                <Route component={Home} />
              </Switch>
            </Suspense>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  };
}

export default App;
