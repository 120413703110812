const ApiSuffix = {
  // logout: "/api/logout/",
  getCityList: "/api/get-city-list/",
  getMenuPageData: "/api/get-menu-page-data/menu/",
  getCityMenu: "/api/get-menu/",
  getStoreCatalogue: "/api/get-store-categories/",
  getCategoryData: "/api/get-products/",
  // getCategoryData: "/api/get-store-menu/",
  getProductDetails: "/api/get-product-info/?displayURL=",
  getAddons: "/api/get-add-ons/?id=",
  getAddressInfo: "/api/get-saved-address/?cityID=",
  addUserAddress: "/api/create-new-address/",
  calculateShipping: "/api/get-shipping-charges/",
  orderRegistration: "/api/register-order/",
  deleteUserAddress: "/api/delete-address/?id=",
  getHomePageData: "/api/home-page-data/",
  blogGeneric: "/api/blog/",
  getBlogData: "/api/get-blog-data/",
  registerReferral: "/api/referral-register/",
  getStoreBills: "/api/get-pos-bills/",
  getBillDetails: "/api/get-bill-details/",
  capturePayment: "/api/capture-payment/",
  // getCityStores: "/get-stores-locations/",
  // New Urls Added
  getMobileData: "/api/get-mobile-data/",
  validateCart: "/api/validate-cart/",
  generateOTP: "/api/send-otp/",
  verifyOTP: "/api/verify-otp/",
  registerNewUser: "/api/register-user/",
  getSavedAddress: "/api/get-saved-address/",
  createNewAddress: "/api/create-new-address/",
  validatePincode: "/api/validate-pincode/",
  applyCoupon: "/api/apply-coupon/",
  registerOrder: "/api/register-order/",
  createOrder: "/api/create-order/",
  getUserProfile: "/api/my-profile/",
  getOrderDetail: "/api/get-order-details/",
  getStoresData: "/api/get-stores/",
};

export default ApiSuffix;
