import React from "react";
import "./Loader.css";
import * as NFIMAGES from "../../Assets/NFImages";
class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render = () => {
    return (
      <div>
        <div className="loader" id="spinner">
          <img
            src={NFIMAGES.DARK_LOGO}
            className="central-icon"
            alt="Food Loader"
          />
        </div>
        <div className="loader-content">New Fair Business Center</div>
      </div>
    );
  };
}

export default Loader;
