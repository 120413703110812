import {
  CHANGE_AUTH_TOKEN,
  CHANGE_AUTH_STATUS,
  CHANGE_USER_PROFILE,
  UPDATE_PHONE_PE,
  LOGIN,
  LOGOUT,
  UPDATE_ADV_DETAILS,
  UPDATE_TAB_VALUE,
  UPDATE_CATEGORY_DATA,
  UPDATE_PRODUCT_DATA,
  UPDATE_VALIDATED_CART_RESPONSE,
  UPDATE_DELIVERY_RESPONSE,
} from "../actionTypes";

const initialState = {
  isAuthenticated: false,
  authToken: null,
  userProfile: {},
  advDetails: {},
  isPhonePe: false,
  activeTabValue: "",
  activeCategoryData: {},
  productCategoryData: {},
  validatedCartResponse: {},
  deliveryResponse: {},
};

//Dummy AdvDetails :
/**
 *details: {
    image: "",
    buttonText: "Explore More",
    buttonRedirectUrl: "",
    announcements: [
      "We are back now with a whole new User Interface.",
      "We have simplified the user flows. Now its easier to locate our products and place the orders.",
      "You can look for your orders in your orders section in your profile tab."
    ]
  }
 */

export default function (state = initialState, action) {
  switch (action.type) {
    case CHANGE_AUTH_TOKEN: {
      const { token } = action.payload;
      return {
        ...state,
        authToken: "Token " + token,
      };
    }
    case CHANGE_AUTH_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        isAuthenticated: status,
      };
    }
    case CHANGE_USER_PROFILE: {
      const { profile } = action.payload;
      return {
        ...state,
        userProfile: profile,
      };
    }
    case LOGIN: {
      const { token, profile } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        authToken: "Token " + token,
        userProfile: profile,
      };
    }
    case LOGOUT: {
      return {
        ...state,
        isAuthenticated: false,
        userProfile: {},
        authToken: null,
      };
    }
    case UPDATE_ADV_DETAILS: {
      const { details } = action.payload;
      return {
        ...state,
        advDetails: details,
      };
    }
    case UPDATE_PHONE_PE: {
      const { status } = action.payload;
      return {
        ...state,
        isPhonePe: status,
      };
    }
    case UPDATE_TAB_VALUE: {
      const { tabValue } = action.payload;
      return {
        ...state,
        activeTabValue: tabValue,
      };
    }
    case UPDATE_CATEGORY_DATA: {
      const { catData } = action.payload;
      return {
        ...state,
        activeCategoryData: catData,
      };
    }
    case UPDATE_PRODUCT_DATA: {
      const { prodData } = action.payload;
      let _listData = { ...state };
      _listData.productCategoryData[prodData.displayURL] = prodData;
      return {
        ...state,
        productCategoryData: _listData.productCategoryData,
      };
    }
    case UPDATE_VALIDATED_CART_RESPONSE: {
      const { cartResponse } = action.payload;
      return {
        ...state,
        validatedCartResponse: cartResponse,
      };
    }
    case UPDATE_DELIVERY_RESPONSE: {
      const { delResponse } = action.payload;
      return {
        ...state,
        deliveryResponse: delResponse,
      };
    }
    default:
      return state;
  }
}
